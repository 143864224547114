import PageTitle from "components/PageTitle";
import TableComponent from "components/TableComponent/index";
import { STATUSES } from "enumerations";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import swal from "sweetalert";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import useQueryString from "utils/useQueryString";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME, sortHourData } from "../enum";
import { tableData } from "./tableInfo";
import { useTranslation } from "react-i18next";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ModalLayout from "components/ModalLayout";
// import AddItemToMenus from "containers/Menus/AddItemToMenus";
import styles from "./index.module.scss";
import { convertToTimeFormat } from "utils/convertor";
import TableFiltersSelector from "components/TableFiltersSelector";
import BranchSelector, { convertBranchData } from "containers/Branch/BranchSelector";
import RemoveNullObjectValue from "utils/RemoveNullObjectValue";
import { useLocation, useNavigate } from "react-router-dom";

const List = () => {
	const { t } = useTranslation();
	const [checkList, setCheckList] = useState();
	const [menuModalState, setMenuModalState] = useState();
	// ---------------------------------------------- new route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
	// ---------------------------------------------- Constance
	const button = {
		title: t(`addAuthor`, { author: t(COMPONENT_NAMES[0]) }),
		link: newRoute.base + newRoute.add,
	};
	const { branchName, ...QueryString } = useQueryString({ sort: "desc" });
	const { page, limit } = QueryString;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const adminState = useSelector((state) => state.admin);
	const isAdmin = adminState.data.userType?.includes("admin");

	// ---------------------------------------------- fetching functions
	const getting = async () =>
		await axiosClient().get(api[API_NAME].list, {
			params: { ...QueryString },
		});
	const changeStatus = async (params) => {
		console.log(params, "changeStatus");
		await axiosClient().patch(api[API_NAME].changeStatus, params);
	};
	const deleting = async (params) => {
		await axiosClient().delete(api[API_NAME].delete + "/" + params?.id);
	};
	const gettingReportCosts = async () => {
		const { from, to } = QueryString;
		return await axiosClient().get(api[API_NAME].list, {
			// params: { limit: 0 },
			params: { from, to, limit: 0, branchId: QueryString?.branchId },
		});
	};
	// ------------------------------------------------------- query Get
	const {
		data: { data = {} } = {},
		error,
		isError,
		isLoading,
		refetch,
	} = useQueryCustom({
		name: `${API_NAME}_get`,
		url: getting,
		params: { ...QueryString },
	});
	// const data = useMemo(() => sortHourData(baseData), [baseData])
	// ---------------------------------------------- mutation Change Status
	const onSuccessDeleteMutation = () => {
		toastify.success({ title: "success" });
	};

	const deleteMutation = useMutationCustom({
		name: `${API_NAME}_delete`,
		url: deleting,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessDeleteMutation,
	});
	// ---------------------------------------------- mutation Change Status
	const onSuccessChangeStatusMutation = () => {
		toastify.success({ title: "success" });
	};

	const changeStatusMutation = useMutationCustom({
		name: `${API_NAME}_changeStatus`,
		url: changeStatus,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessChangeStatusMutation,
	});
	// ---------------------------------------------- logs
	console.log({ data, error, isError, isLoading });
	// ---------------------------------------------- action click table

	const actionsOperation = ({ data, status }) => {
		swal({
			title: "Are you sure?",
			text: `You want to ${status?.label?.toLowerCase()} this ${COMPONENT_NAMES[0]}`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willBe) => {
			if (willBe) {
				if (status.label === "delete") deleteMutation.mutate({ id: data });
				else changeStatusMutation.mutate({ ids: data, isActive: status.value });
			}
		});
	};

	const clickAction = ({ data, status }) => {
		const newData = data.map((x) => x.id);
		actionsOperation({ data: newData, status });
	};

	const handleAction = (status) => {
		const data = Object.keys(checkList);
		console.log({ data, status });
		actionsOperation({ data, status: status });
	};

	// ---------------------------------------------- breadcrumbs

	const handleShowAddItemModal = (x) => setMenuModalState(x);
	// ---------------------------------------------- breadcrumbs
	const breadcrumbs = [{ title: "Dashboard", link: routes.dashboard.base }, { title: COMPONENT_NAMES[0], link: newRoute.base }, { title: "List" }];
	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, []);

	const tableOptions = tableData({
		onClick: clickAction,
		newRoute,
		active: {
			manuel: [
				{
					icon: "mdi mdi-content-copy",
					route: "add",
				},
				{
					icon: "mdi mdi-food",
					route: routes.menus.base + routes.menus.addItemsToMenus,
					class: "btn-success",
					// onClick: handleShowAddItemModal
					manual: true,
				},
			],
		},
	});

	//  ----------------------------------------- LOGS ----------------------------------------- //
	console.log({ checkList, data }, "checkList");
	const gettingStatistics = async () => {
		const { from, to } = QueryString;

		return await axiosClient().get(api[API_NAME].list, {
			params: { from, to },
		});
	};

	const exportToCSV = ({ apiData, fileName, head, cols }) => {
		const workbook = XLSX.utils.book_new();
		// const myHeader = ["id","name","location"];
		const worksheet = XLSX.utils.json_to_sheet(apiData, { header: head });

		const range = XLSX.utils.decode_range(worksheet["!ref"]);
		range.e["c"] = head.length - 1;
		worksheet["!ref"] = XLSX.utils.encode_range(range);
		worksheet["!autofilter"] = { ref: `A1:${String.fromCharCode(65 + head.length - 1)}1` };
		worksheet["!cols"] = cols;

		XLSX.utils.book_append_sheet(workbook, worksheet, "tab1");
		XLSX.writeFile(workbook, fileName + ".xlsx");
	};

	// const handleGetReports = () => {
	//   let cols = [
	//     { wch: 10 },
	//     { wch: 10 },
	//     { wch: 10 },
	//     { wch: 10 },
	//     { wch: 10 },
	//     { wch: 25 },
	//     { wch: 30 },
	//     { wch: 20 },
	//   ];

	//   const head = [
	//     t("name"),
	//     t("buyPrice"),
	//     t("price"),
	//     t("cost"),
	//     t("category"),
	//     t("integrationCode"),
	//     t("orderNumber"),
	//     t("orderId"),
	//   ];

	//   let calcAverage = {
	//     cost: 0,
	//     buyPrice: 0,
	//     category: 0,
	//     price: 0,
	//   }

	//   const params = data?.result?.map((y) => {

	//     calcAverage.cost += (y?.cost || 0);
	//     calcAverage.buyPrice += (y?.buyPrice || 0);
	//     calcAverage.price += (y?.price || 0);

	//     let newData = {
	//       [t("name")]: y.productName,
	//       [t("buyPrice")]: y.buyPrice,
	//       [t("price")]: y.price,
	//       [t("cost")]: y.cost,
	//       [t("category")]: y.category,
	//       [t("integrationCode")]: y.integrationCode,
	//       [t("orderNumber")]: y.orderNumber,
	//       [t("orderId")]: y.orderId,
	//     }

	//     return newData;
	//   });

	//   let finalData = {
	//     [t("name")]: "",
	//     [t("buyPrice")]: calcAverage.buyPrice,
	//     [t("price")]: calcAverage.price,
	//     [t("cost")]: calcAverage.cost,
	//     [t("category")]: "",
	//     [t("integrationCode")]: "",
	//     [t("orderNumber")]: "",
	//     [t("orderId")]: "",
	//   }

	//   params.push(finalData);

	//   console.log("* * * handleGetReports", { params, cols, finalData });

	//   exportToCSV({
	//     apiData: params, fileName: `statistic-from-${QueryString.from || "now"}-to-${QueryString.to || "now"}`, head, cols
	//   });

	// };

	const handleGetReports = () => {
		let cols = [{ wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 25 }, { wch: 10 }, { wch: 25 }, { wch: 30 }, { wch: 20 }, { wch: 30 }];

		const head = [
			t("name"),
			t("buyPrice"),
			t("price"),
			t("serviceChargeIncludedPrice"),
			t("cost"),
			t("region"),
			t("hotelName"),
			t("category"),
			t("integrationCode"),
			t("orderNumber"),
			t("orderId"),
		];

		let calcAverage = {
			cost: 0,
			buyPrice: 0,
			category: 0,
			price: 0,
			serviceChargeIncludedPrice: 0,
		};

		gettingReportCosts()
			.then((x) => {
				console.log("x ===>", { x });

				const data = x?.data?.result?.map((y) => {
					calcAverage.cost += y?.cost || 0;
					calcAverage.buyPrice += y?.buyPrice || 0;
					calcAverage.price += y?.price || 0;
					calcAverage.serviceChargeIncludedPrice += y?.serviceChargeIncludedPrice || 0;

					let newData = {
						[t("name")]: y.productName,
						[t("buyPrice")]: y.buyPrice,
						[t("price")]: y.price,
						[t("serviceChargeIncludedPrice")]: y.serviceChargeIncludedPrice,
						[t("cost")]: y.cost,
						[t("region")]: y.region?.name,
						[t("hotelName")]: y.hotelName,
						[t("category")]: y.category,
						[t("integrationCode")]: y.integrationCode,
						[t("orderNumber")]: y.orderNumber,
						[t("orderId")]: y.orderId,
					};

					return newData;
				});

				let finalData = {
					[t("name")]: "",
					[t("buyPrice")]: calcAverage.buyPrice,
					[t("price")]: calcAverage.price,
					[t("serviceChargeIncludedPrice")]: calcAverage.serviceChargeIncludedPrice,
					[t("cost")]: calcAverage.cost,
					[t("region")]: "",
					[t("hotelName")]: "",
					[t("category")]: "",
					[t("integrationCode")]: "",
					[t("orderNumber")]: "",
					[t("orderId")]: "",
				};

				data.push(finalData);

				// console.log("* * * handleGetReports", { params, cols, finalData });

				exportToCSV({
					apiData: data,
					fileName: `statistic-from-${QueryString.from || "now"}-to-${QueryString.to || "now"}`,
					head,
					cols,
				});
			})
			.catch((err) => {
				console.log({ err });
			});
	};

	const changeUrl = (params) => ({
		pathname: location.pathname,
		search: new URLSearchParams(RemoveNullObjectValue({ ...QueryString, ...params })).toString(),
	});

	// const selector = [
	// 	<BranchSelector
	// 		value={
	// 			QueryString?.branchId &&
	// 			convertBranchData({
	// 				name: branchName,
	// 				id: QueryString?.branchId,
	// 			})
	// 		}
	// 		isClearable
	// 		{...{
	// 			label: "branch",
	// 			setState: (params) => {
	// 				console.log({ params });
	// 				if (params)
	// 					navigate(
	// 						changeUrl({
	// 							branchName: params?.name,
	// 							// branchImage: params?.image,
	// 							branchId: params?.value,
	// 						})
	// 					);
	// 				else
	// 					navigate(
	// 						changeUrl({
	// 							branchName: null,
	// 							// branchImage: null,
	// 							branchId: null,
	// 						})
	// 					);
	// 			},
	// 		}}
	// 	/>,
	// ];

	return (
		<div>
			<PageTitle
				title={t("listsAuthor", { author: t(COMPONENT_NAMES[0]) })}
				breadcrumbs={breadcrumbs}
				// button={button}
			/>
			{/* <ModalLayout {...{
        show: menuModalState,
        setShow: setMenuModalState,
        classNames: {
          modal: styles.modal,
          dialog: styles.modalDialog,
          content: styles.modalContent,
        },
        title: "add item to menus"
      }}>
        <AddItemToMenus product={menuModalState} handleClose={setMenuModalState} />
      </ModalLayout> */}
			{/* {isAdmin ? <TableFiltersSelector data={selector} /> : ""} */}
			<div className="w-50">
				<BranchSelector
					value={
						QueryString?.branchId &&
						convertBranchData({
							name: branchName,
							id: QueryString?.branchId,
						})
					}
					isClearable
					{...{
						label: "branch",
						setState: (params) => {
							console.log({ params });
							if (params)
								navigate(
									changeUrl({
										branchName: params?.data?.name,
										// branchImage: params?.image,
										branchId: params?.value,
									})
								);
							else
								navigate(
									changeUrl({
										branchName: null,
										// branchImage: null,
										branchId: null,
									})
								);
						},
					}}
				/>
			</div>
			<TableComponent
				{...{
					QueryString,
					data: data?.result,
					tableOptions,
					loading: isLoading,
					search: {
						placeholder: t("search"),

						defaultValue: QueryString.search_text,
					},
					title: `${COMPONENT_NAMES[1]}`,
					button: button,
					count: data?.total,
					pagination: {
						activePage: page,
						pages: Math.ceil(data?.total / limit),
						limited: 2,
					},
					checkbox: {
						state: checkList,
						setState: setCheckList,
					},
					actions: {
						onClick: handleAction,
					},
					status: STATUSES,
					excelButton: {
						show: true,
						disabled: !data?.result,
						onClick: handleGetReports,
					},
					dating: {
						from: {
							changeTime: (date) => new Date(date).setHours(8, 0, 0, 0),
						},
						to: {
							changeTime: (date) => new Date(date).setHours(3, 0, 0, 0),
						},
					},
				}}
			/>
		</div>
	);
};

export default List;
